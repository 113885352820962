<script>
export default {
  name: "TermsModal",
  props: ['property'],
  mounted() {
    localStorage.setItem('property', JSON.stringify(this.property))
  }
}
</script>

<template>
  <div class="modal fade property-modal" id="TermsModal" tabindex="-1" aria-labelledby="TermsModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0">
          <p class="modal-title" id="TermsModalLabel">Terms and conditions</p>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p class="modal-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum.
            <br/><br/>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem
            aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
          </p>
          <div class="d-flex justify-content-center">
            <button class="agree-btn"
                    @click="this.$router.push({path: '/investment-summary'})"
                    data-bs-dismiss="modal">
              Agree</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>