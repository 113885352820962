<script>
export default {
  name: "ViewWhitepaperBlock"
}
</script>

<template>
  <div class="view-whitepaper position-relative">
    <img class="position-absolute lightning-icon" src="@/assets/icons/whitepaper/plane.png">
    <div class="section d-flex flex-column justify-content-center align-items-center">
      <p class="text">Get an in-depth understanding of the Liquid Estate ecosystem.</p>
      <a target="_blank" href="https://bit.ly/LEpaper" class="show-whitepaper-btn">
        View Whitepaper
        <i class="fi fi-sr-angle-small-right"></i>
      </a>
    </div>
    <img class="position-absolute plane-icon" src="@/assets/icons/whitepaper/lightning.png">
  </div>
</template>

<style scoped>

</style>