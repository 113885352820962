<script>
export default {
  name: "JoinCommunitySection"
}
</script>

<template>
<div class="community-section d-flex flex-column align-items-center">
  <p class="title mb-5">Join Our Community</p>
  <div class="d-flex w-100 justify-content-between community-list">
    <div class="community-block">
      <img src="@/assets/icons/communities/Discord.svg"/>
      <p style="color: #7289DA" class="social-media-title">Discord</p>
      <a target="_blank" href="https://bit.ly/3O8KjCS" class="follow-link-btn">
        Join Server
        <i class="fi fi-sr-angle-small-right"></i>
      </a>
    </div>
    <div class="community-block">
      <img src="@/assets/icons/communities/Telegram.svg"/>
      <p style="color: #1E96C8" class="social-media-title">Telegram</p>
      <a target="_blank" href="https://bit.ly/3Pp9C4k" class="follow-link-btn">
        Join Channel
        <i class="fi fi-sr-angle-small-right"></i>
      </a>
    </div>
    <div class="community-block">
      <img src="@/assets/icons/communities/Twitter.svg"/>
      <p style="color: #2DAAE1" class="social-media-title">Twitter</p>
      <a target="_blank" href="https://bit.ly/3PcWT59" class="follow-link-btn">
        Follow Us
        <i class="fi fi-sr-angle-small-right"></i>
      </a>
    </div>
    <div class="community-block">
      <img src="@/assets/icons/communities/Medium.svg"/>
      <p class="social-media-title">Medium</p>
      <a target="_blank" href="https://bit.ly/3cdUE2X" class="follow-link-btn">
        Follow Us
        <i class="fi fi-sr-angle-small-right"></i>
      </a>
    </div>
  </div>
</div>
</template>

<style scoped>

</style>